import PrimaryButton from '@/components/atoms/Button/PrimaryButton'
import { makeStyles } from '@material-ui/core'
import { Chart, LegendItem } from 'chart.js'
import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import GhgDoughnut, { GhgDoughnutProps } from '.'
import { DefaultDoughnutLegend } from './legend'
import AnalysisLegends from '../Legend/analysisLegends'
import { CustomTooltip } from '@/components/atoms/CustomTooltip'

const PaddingWrapper = styled.div`
    font-weight: 600;
    font-size: 16px;
    text-align: center;
`

type TitleProps = {
    title: string
}

const Title = (props: TitleProps) => (
    <PaddingWrapper>
        <h4>{props.title}</h4>
    </PaddingWrapper>
)

type CustomChartProps = Pick<GhgDoughnutProps, 'labels' | 'dataset'> & {
    noBorder?: boolean
    allScope?: boolean
    onDetailClick: () => void
    isFixedLegend?: boolean
    disabled?: boolean
    isPrinting?: boolean
}
const useStyles = makeStyles({
    dflex: {
        display: 'flex',
    },
    justifyContent: {
        justifyContent: 'space-between',
    },
    boxGHGChart: {
        border: '1px solid #DDDDDD',
    },
    circleChart: {
        padding: '20px',
        width: '100%',
        height: '100%',
        minHeight: 480,
    },
    doughnut: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
    },
    scopeDoughnut: {
        overflow: 'hidden',
        flex: 1,
        height: '100%',
    },
    btnWrapper: {
        marginTop: 20,
    },
})
const CustomChart = (props: ScopeDoughnutProps) => {
    const [legendItems, setLegendItems] = useState<Array<LegendItem>>()
    const getLegendItems = useCallback((chart: Chart) => {
        if (chart) {
            //@ts-ignore
            setLegendItems(chart.legend.legendItems)
        }
    }, [])
    const classes = useStyles()

    return (
        <>
            <div className={`${classes.circleChart} ${classes.boxGHGChart}`}>
                <div className={classes.doughnut}>
                    <Title title={props.title} />
                    <GhgDoughnut
                        labels={props.labels}
                        dataset={props.dataset}
                        getLegendItems={getLegendItems}
                        noLegend
                    />
                    {legendItems &&
                        (props.isFixedLegend ? (
                            <div style={{ marginTop: 20, width: '100%', height: '100%' }}>
                                <AnalysisLegends legendItems={legendItems} />
                            </div>
                        ) : (
                            <DefaultDoughnutLegend
                                legendItems={legendItems}
                                allScope={props.allScope}
                                height={props.isPrinting ? '100%' : 150}
                            />
                        ))}

                    {props.isPrinting ? null : (
                        <div className={classes.btnWrapper}>
                            {props.disabled ? (
                                <CustomTooltip
                                    title="現在、ご契約中のプランは「スターター」です。 Scope3を利用するには「ベーシック」または「エンタープライズ」へのアップグレードが必要です。管理者の方は管理画面のプラン情報からお問い合わせください。"
                                    width={400}
                                >
                                    <span>
                                        <PrimaryButton
                                            width={83}
                                            height={35}
                                            fontSize={14}
                                            onClick={props.onDetailClick}
                                            disabled={props.disabled}
                                        >
                                            詳細
                                        </PrimaryButton>
                                    </span>
                                </CustomTooltip>
                            ) : (
                                <PrimaryButton
                                    width={83}
                                    height={35}
                                    fontSize={14}
                                    onClick={props.onDetailClick}
                                    disabled={props.disabled}
                                >
                                    詳細
                                </PrimaryButton>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

type ScopeDoughnutProps = TitleProps & CustomChartProps
const ScopeDoughnut = (props: ScopeDoughnutProps) => {
    const classes = useStyles()
    return (
        <>
            <div className={classes.scopeDoughnut}>
                <CustomChart {...props} />
            </div>
        </>
    )
}

export default ScopeDoughnut
