import React from 'react'
// @ts-ignore
import Overview from '@/components/template/Home/Overview'
import { withLayout } from '@/components/template/Layout'

// markup
const IndexPage = () => {
    return (
        <main>
            <div style={{ width: '100%' }}>
                <title>ダッシュボード｜ScopeX</title>
            </div>
            <Overview />
        </main>
    )
}

export default withLayout(IndexPage)
